const t = {
  translation: {
    LOGIN_WITH: 'Login with',
    LOGIN_BUTTON_TEXT: 'Login',
    SIGN_UP_LINK_TEXT: 'Sign up',
    NEW_USER_REGISTRATION_TEXT: 'New User Registration',
    FIRSTNAME: 'First name',
    LASTNAME: 'Last name',
    EMAIL: 'Email',
    REPEAT_EMAIL: 'Repeat email',
    PASSWORD: 'Password',
    REPEAT_PASSWORD: 'Repeat password',
    TERMS_ACCEPT: 'I accept the terms and conditions',
    CREATE_ACCOUNT_BUTTON: 'Create account',
    USER_PROFILE_TITLE: 'User Profile',
    PROFILE_PICTURE_TITLE: 'Profile Picture',
    INTRO_IN_THEORY_OF_CHANGE: 'Introduction in theory of change',
    INTRO: 'Intro',
    TOC_FLOWS: 'TOC Flows',
    NEW_TOC_FLOW: 'New TOC Flow',
    PROJECT_INFO: 'Project Info',
    ACTION_AREAS: 'Action Areas',
    CONTRIBUTORS: 'Contributors',
    TOC_FLOW_COMPONENTS: 'TOC Flow Components',
    TOC_LEVEL: 'ToC Level',
    MINDMAP: 'Mindmap.',
    SAVE_TOC_FLOW: 'Save TOC Flow',
    NEXT: 'Next',
    MY_TOC_FLOWS: 'My TOC Flows',
    LOGGED_IN_AS: 'Logged in as:',
    SIGN_OUT: 'Sign Out',
    MY_PROFILE: 'My Profile',
    PROFILE: 'Profile',
    DASHBOARD: 'Dashboard',
    INVITATIONS: 'Invitations',
    PENDING_INVITATIONS: 'Pending Invitations',
    INVITATION: 'Invitation',
    ACTIONS: 'Actions',
    SEARCH_FOR_INVITATIONS: 'Search for invitations...',
    NO_INVITATIONS_FOUND: 'No invitations were found.',
    JOIN: 'Join',
    DISMISS: 'Dismiss',
    DECLINE: 'Decline',
    ACCOUNT_SETTINGS: 'Account Settings',
    NAME: 'Name',
    MY_FLOWS: 'My Flows',
    LEADING: 'Leading',
    LEADER: 'Leader',
    MEMBER: 'Member',
    REVIEWER: 'Reviewer',
    PROJECTS: 'Projects',
    NEW_PROJECT: 'New Project',
    CREATE_PROJECTS: 'Create Projects',
    PROJECT_TITLE: 'Title',
    PROJECT_FUNDERS: 'Funders',
    PROJECT_PARTNERS: 'Partners',
    RELATED_SDGS: 'Related SDGs',
    EDIT_TEAM: 'Edit Team',
    YOUR_TEAM_NAME: 'Your team name',
    YOUR_TEAM_DESC: 'Your team description',
    CREATE_A_NEW_TEAM: 'Create A New Team',
    VIEW_DETAILS: 'View Details',
    ISSUE_DATE: 'Issue Date',
    TITLE: 'Title',
    TYPE: 'Type',
    COLLECTIONS: 'Collections',
    PII_STATUS: 'PII Status',
    CERTIFIED: 'Certified',
    FAIR_SCORING: 'Fair Scoring',
    SEARCH_FOR_TEAMS: 'Search for teams...',
    SEARCH_FOR_COLLECTIONS: 'Search for collections...',
    SEARCH_FOR_RESOURCES: 'Search for resources...',
    COLLECTION_NAME: 'Name',
    COLLECTION_RESOURCES: 'Resources',
    COLLECTION_FAIR_SCORING: 'Fair Scoring',
    PUBLISHED_RESOURCES: 'Published Resources',
    RESOURCES_UNDER_PREPARATION: 'Resources Under Preparation',
    RESOURCES_UNDER_REVIEW: 'Resources Under Review',
    UNPUBLISHED_RESOURCES: 'Unpublished Resources',
    PUBLISHED: 'Published',
    UNPUBLISHED: 'Unpublished',
    CREATE_NEW_TASK: 'Create new task',
    EDIT_COLLECTION: 'Edit Collection',
    SAVE_CHANGES: 'Save Changes',
    NEW_KEYWORD: 'New keyword',
    COLLECTION_ENTER_KEYWORD: 'Enter a keyword.',
    COLLECTION_ADD_KEYWORD: 'Add',
    NO_AVAILABLE_KEYWORDS: 'No keywords are available.',
    COLLECTION_KEYWORD: 'Keyword',
    NEW_COUNTRY: 'New country',
    COLLECTION_ENTER_COUNTRY: 'Enter a country.',
    COLLECTION_ADD_COUNTRY: 'Add',
    NO_AVAILABLE_COUNTRIES: 'No countries are available.',
    COLLECTION_COUNTRY: 'Country',
    TEMPORAL_FROM_DATE: 'From date',
    TEMPORAL_TO_DATE: 'To date',
    VIEW_COLLECTION_RESOURCES: 'View Resources',
    HIDE_COLLECTION_RESOURCES: 'Hide Resources',
    TEMPORAL_COVERAGE_TYPE: 'Type',
    TEMPORAL_DATE: 'Date',
    TEMPORAL_DESCRIPTION: 'Description',
    TEMPORAL_ENTER_DESCRIPTION: 'Enter a description for the time period.',
    NEW_RESOURCE: 'New Resource',
    EDIT_RESOURCE: 'Edit Resource',
    RESOURCE_TITLE: 'Resource Title',
    RESOURCE_DESCRIPTION: 'Resource Description',
    RESOURCE_TYPE: 'Resource Type',
    RESOURCE_SUBTYPE: 'Resource Subtype',
    RESOURCE_METADATA_AUTHORING_TEAM: 'Metadata Authoring Team',
    CREATE_TASK_AND_SEND_INVITES: 'Create Task & Send Invites',
    CLEAR_ALL_FIELDS: 'Clear all fields',
    PUBLISH_RESOURCE: 'Publish Resource',
    SELECT_TARGET_REPOSITORY: 'Select target repository',
    CHECK: 'Check',
    METADATA_PROPERTY_CONDITION: 'Metadata Property Condition',
    SCORING_IN_FAIR: 'Scoring in FAIR',
    RESOURCE_MEETS_CONDITION: 'Resource meets the condition',
    RECOMMENDATION: 'Recommendation',
    FINDABLE: 'Findable',
    ACCESSIBLE: 'Accessible',
    INTEROPERABLE: 'Interoperable',
    REUSABLE: 'Reusable',
    REASON_FOR_REGISTRATION: 'Reason for registration',
    MY_TASKS: 'My Tasks',
    MY_INITIATIVES: 'My Initiatives',
    PROJECT: 'Project',
    WHEN: 'When',
    DESCRIPTION: 'Description',
    FIND_PROJECTS_INITIATIVES: 'Find Projects/Initiatives',
    SEARCH_BY_COUNTRY: 'Search by country',
    SEARCH_BY_ORGANIZATION: 'Search by organizations',
    SEARCH: 'Search',
    REQUEST_ACCESS: 'Request Access',
    REVIEWS: 'Reviews',
    MENTIONS: 'Mentions',
    OPEN: 'Open',
    ROLE: 'Role',
    STAKEHOLDER: 'Stakeholder',
    ADMIN_AREA: 'Admin Area',
    TOC_REQUESTS: 'TOC Requests',
    ACCEPT: 'Accept',
    REJECT: 'Reject',
    SEARCH_FOR_REQUEST: 'Search for request...',
    NO_REQUESTS_FOUND: 'No requests found.',
    RATIONALE_TITLE: 'Rationale for rejection',
    FLOW_OVERVIEW: 'Overview',
    OVERVIEW: 'Overview',
    STATUS: 'Status',
    PROJECT_SETTINGS: 'Settings',
    PROJECT_TEAM: 'Team',
    INVOLVED_STAKEHOLDERS: 'Involved Stakeholders',
    SAVE: 'Save',
    PENDING_REQUESTS: 'Pending Requests',
    PROJECT_DESCRIPTION: 'Description',
    PROJECT_MINDMAP: 'Mindmap',
    EXPORT: 'Export',
    RESPONSIBLE_ENTITIES: 'Responsible Entities',
    NO_RESPONSIBLE_ENTITIES_FOUND: 'No responsible entities were found.',
    TOCS: 'ToCs',
    TARGETS: 'Targets',
    INDICATORS: 'Indicators',
    INDICATOR: 'Indicator',
    COMMENTS: 'Comments',
    REVIEWER_COMMENTS: 'Reviewer Comments',
    STAKEHOLDER_COMMENTS: 'Stakeholder Comments',
    NARRATIVE: 'Narrative',
    NO_ACTIONS_FOUND: 'No actions were found',
    ACTION: 'Action',
    TEXT: 'Text',
    CHANGE_ELEMENT_TEXT: 'Text Editor',
    SET_TEXT: 'Save',
    GENDER_DIMENSION: 'Gender Dimension',
    PREVIOUS: 'Previous',
    WORK_PACKAGES: 'Work Packages',
    CHALLENGE: 'Challenge',
    CHANGE_PROCESS: 'Change Process',
    ASSUMPTIONS_CAUSAL_LOGIC: 'Assumptions/Causal Logic',
    VERSION: 'Version',
    TIMESTAMP: 'Timestamp',
    TOCS_LIST_TITLE: 'TOCs',
    NO_INDICATORS_FOUND: 'No indicators were found.',
    NO_TOCS_FOUND: 'No TOCs were found.',
    TOC_VERSION_HISTORY_TITLE: 'TOC Version History',
    NO_TOC_VERSIONS_FOUND: 'No TOC versions were found.',
    CONTRIBUTION: 'Contribution',
  },
};

export default t;
